import React, { StrictMode, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, useLocation } from "react-router-dom";
import AOS from "aos";

import { useAosApply } from "@common/hooks";
import { AppProvider } from "@common/app-provider";

// ---------- builder ----------
// import BlogBuilder from "@blog/app";
// import Builder from "@ecommerce/shop/app";
// import Builder from "@ecommerce/admin/app";
import Builder from "@skynoveau-ui/app";
// import Builder from "@static/app";

// Note : don't change the order
import "@common/theme/root.css";
import "@common/global.css";
import "@common/theme/text.theme.css";
import "@common/theme/title.theme.css";
import "@common/global.override.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

let RenderApp = ({ children }) => {
  const location = useLocation();

  useAosApply(location.pathname);

  useEffect(() => {
    AOS.init({
      offset: 100,
    });
  }, []);

  return (
    <AppProvider>
      <>{children}</>
    </AppProvider>
  );
};

root.render(
  <BrowserRouter>
    <StrictMode>
      <RenderApp>
        <Builder />
      </RenderApp>
    </StrictMode>
  </BrowserRouter>
);
