import React from "react";

import { Loader as BaseLoader } from "@components/loader/basic";
import { LOADER_SIZE } from "@components/loader/constant";

import styles from "./fullscreen.module.css";

export const Loader = ({
  size = LOADER_SIZE.BASE,
  className,
  allowText = true,
  loading = true,
  transparent = true,
  color = "var(--black-color)",
}) => {
  return (
    <div
      className={`${styles.fadeOverlay} ${
        loading ? styles.fadeIn : styles.fadeOut
      }`}
    >
      <div
        className={`${styles.loaderContainer} ${
          loading ? styles.contentFadeIn : styles.contentFadeOut
        }`}
      >
        <BaseLoader
          size={size}
          transparent={transparent}
          allowText={allowText}
          color={color}
          className={className}
        />
      </div>
    </div>
  );
};
