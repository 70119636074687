import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";

// import { queryClient } from "@ecommerce/shop/service/query";

const queryClient = new QueryClient();

export const AppProvider = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <>{children}</>
    </QueryClientProvider>
  );
};
